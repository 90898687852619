import React, { useRef, useState } from 'react';
import './Nav.css'; // Ensure this file contains the necessary CSS
import Dropdown from 'react-bootstrap/Dropdown';
import { BsList, BsFillHouseFill, BsBriefcaseFill , BsCameraVideoFill, BsBackpackFill } from "react-icons/bs";

import { Link, useLocation } from 'react-router-dom';

function Nav() {
    const [show, setShow] = useState(false);
    const toggleRef = useRef(null);
    const location = useLocation(); // Hook to get the current location

    const handleItemClick = () => {
        if (toggleRef.current) {
            // Use setTimeout to ensure blur happens after the dropdown closes
            setTimeout(() => {
                toggleRef.current.blur();
            }, 100); // Adjust delay as needed
        }
    };

    const handleToggle = (nextShow) => {
        setShow(nextShow);
    };

    const isActive = (path) => location.pathname === path;

    return (
        <div id="navBar">
            <Dropdown 
                id='dropdown'  
                show={show} 
                onToggle={handleToggle}
                onClick={() => setShow(!show)} // Toggle dropdown visibility
            >
                <Dropdown.Toggle 
                    id="dropdown-basic" 
                    ref={toggleRef} 
                    className={show ? 'rotated' : ''}
                >
                    <BsList />
                </Dropdown.Toggle>

                <Dropdown.Menu id='dropdown-items'>
                    <Dropdown.Item 
                        onClick={handleItemClick} 
                        as={Link} 
                        to="/" 
                        className={isActive('/') ? 'active-item' : ''}
                    >
                        <div className='preIcon'><BsFillHouseFill /></div> Home
                    </Dropdown.Item>
                    <Dropdown.Item 
                        onClick={handleItemClick} 
                        as={Link} 
                        to="/Experience" 
                        className={isActive('/Experience') ? 'active-item' : ''}
                    >
                        <div className='preIcon'><BsBriefcaseFill /></div>Experience
                    </Dropdown.Item>
                    <Dropdown.Item 
                        onClick={handleItemClick} 
                        as={Link} 
                        to="/Videos" 
                        className={isActive('/Videos') ? 'active-item' : ''}
                    >
                        <div className='preIcon'><BsCameraVideoFill /></div>Videos
                    </Dropdown.Item>
                    <Dropdown.Item 
                        onClick={handleItemClick} 
                        as={Link} 
                        to="/Projects" 
                        className={isActive('/Projects') ? 'active-item' : ''}
                    >
                        <div className='preIcon'><BsBackpackFill /></div>Projects
                    </Dropdown.Item>
                </Dropdown.Menu>
                <h5 id='menuTitle'>Menu</h5>
            </Dropdown>
            <h5 id='nameTitle'>Dimostenis Nikolakopoulos</h5>
            <img src="./assets/logo.png" alt="" />
        </div>
    );
}

export default Nav;
