import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './Home.css';
import { Link } from 'react-router-dom';

import { BsLinkedin } from "react-icons/bs";

const Home = () => {
  return (
    <div id='homeContainer' className='fade-in'>
        <div className="home-background fade-in">
        <Container>
            <Row className="justify-content-center header-row">
            <Col md={9} className="header">
                <h1 className="primary-text">Welcome to My Professional Website</h1>
                <p className="secondary-text">
                I'm Dimostenis, a passionate web developer skilled in JavaScript and frontend technologies.
                Check out my experience, projects, and videos to know more about my work.
                </p>
                <Button onClick={()=>{window.location.href = "https://www.linkedin.com/in/dimos-nikolakopoulos/"}} variant="outline-dark" className="learn-more-button"><BsLinkedin className='elevateIcon'/> LinkedIn</Button>
            </Col>
            </Row>
            <Row className="justify-content-center content-row">
            <Col md={3} className="card">
                <h3 className="card-title">Experience</h3>
                <p className="card-text">Dive into my professional journey and see what I've accomplished over the years.</p>
                <Button as={Link} to="Experience" variant="link" className="link-button">Explore Experience</Button>
            </Col>
            <Col md={3} className="card">
                <h3 className="card-title">Videos</h3>
                <p className="card-text">Watch videos that showcase my work and tutorials on various technologies.</p>
                <Button as={Link} to="Videos" variant="link" className="link-button">Watch Videos</Button>
            </Col>
            <Col md={3} className="card">
                <h3 className="card-title">Projects</h3>
                <p className="card-text">Take a look at some of the exciting projects I've worked on.</p>
                <Button as={Link} to="Projects" variant="link" className="link-button">View Projects</Button>
            </Col>
            
            </Row>
        </Container>
        </div>
    </div>
  );
};

export default Home;
