import {React, useState} from "react";
import "./Footer.css";

import {BsEnvelopeFill ,BsTelephoneFill, BsLinkedin} from "react-icons/bs";

function Footer(){
    return(
        <div id="footer">
            <div>
                <p><BsEnvelopeFill  className="icon"/> <a href="mailto:dnikolas1993@yahoo.com">dnikolas1993@yahoo.com</a></p>
                <p><BsLinkedin className="icon"/> <a href="https://www.linkedin.com/in/dimos-nikolakopoulos/" target="_blank">Dimos Nikolakopoulos</a></p>
                <p><BsTelephoneFill  className="icon"/> +1 (646) 409 0705</p>
            </div>
        </div>
    );
}

export default Footer;