import {React, useState} from "react";
import "./Videos.css";
import YouTube from 'react-youtube';

const videos = [
    { title: "Task Manager", videoId: "kT4uMlV05Cw" },
    { title: "Stohos Angular", videoId: "YrigaGXr--E" },
    { title: "Yfansis Garment LTD", videoId: "smRr8co_qEQ" },
    // Add more videos as needed
];


function Videos(){
    const [currentVideo, setCurrentVideo] = useState(videos[0]);

    const handleVideoChange = (video) => {
        setCurrentVideo(video);
    };

    const opts = {
        height: '500',
        width: '100%',
        playerVars: {
            autoplay: 1,
        },
    };

    return(
        <div id="videoContainer" className='fade-in'>
                <div className="video-player-container fade-in">
                    <div className="video-player">
                        <h2>Now Playing: {currentVideo.title}</h2>
                        <YouTube id="actualPlayer" videoId={currentVideo.videoId} opts={opts} />
                    </div>
                    <div className="video-list">
                        <h2>Video List</h2>
                        <ul>
                            {videos.map((video, index) => (
                                <li
                                    key={index}
                                    onClick={() => handleVideoChange(video)}
                                    className={video.videoId === currentVideo.videoId ? 'active' : ''}
                                >
                                    {video.title}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
        </div>
    );
}

export default Videos