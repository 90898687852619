import React, { useState, useEffect, useRef } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BsChevronDoubleUp } from "react-icons/bs";

import Nav from './Nav';
import Home from './Home';
import Experience from './Experience';
import Videos from './Videos';
import Projects from './Projects';
import Footer from './Footer';
import './App.css';

function App() {
  const [showButton, setShowButton] = useState(false);
  const mainRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const mainElement = mainRef.current;

    const handleScroll = () => {
      const threshold = 0.02 * mainElement.scrollHeight;

      if (mainElement.scrollTop > threshold) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    mainElement.addEventListener('scroll', handleScroll);
    return () => mainElement.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (mainRef.current) {
      mainRef.current.scrollTop = 0; // Instant scroll to top on route change
    }
  }, [location]);

  const scrollToTop = () => {
    if (mainRef.current) {
      mainRef.current.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll for button
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <Nav id='Nav'/>
      </header>
      <main className='fade-in' ref={mainRef} style={{ overflowY: 'auto', maxHeight: '100vh' }}>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/Experience' element={<Experience/>}/>
          <Route path='/Videos' element={<Videos/>}/>
          <Route path='/Projects' element={<Projects/>}/>
          <Route path='*' element={<Home />}/>
        </Routes>
      </main>
      {showButton && (
        <button onClick={scrollToTop} className="back-to-top" title="Go to top">
          <BsChevronDoubleUp />
        </button>
      )}
      <footer>
        <Footer/>
      </footer>
    </div>
  );
}

export default App;
