import {React , useEffect} from "react";
import './Projects.css';

import { BsGithub, BsFillPlayCircleFill   } from "react-icons/bs";

function Projects() {
    return(
        <div id="projectcotnainer" className='fade-in'>
            <div className="project-item">
                <img src="./assets/projects/reactLogo.png" alt="React" className="cornerLogo"/>
                <img src="./assets/projects/reservations.png" alt="Reservations" />
                <h2>Reservation & Handler</h2>
                <h4>Description</h4>
                <p>
                    Reservations & Handler are two integrated mechanisms. Together, they function as a component allowing restaurant guests to make reservations through the reservation system. The handler system enables the owner and staff to edit, create, and search for reservations. With modifications, it can be adapted for any booking webpage.
                </p>
                <div className="availability">
                    <div>
                        <h6>Available for Trial</h6>
                        <p className="unavailable">
                            No
                        </p>
                    </div>
                    <div>
                        <h6>Available Code Review</h6>
                        <p>Can be provided<br/>(after request)</p>
                    </div>
                </div>
                <h4>Technical Summary</h4>
                <p>  
                    Reservations & Handling comprises two separate mechanisms on the front end and one on the backend, working together to create and manage reservations. Developed with React.js, Node.js, and phpMyAdmin, it utilizes HTML, CSS, JavaScript, and MySQL.
                </p>
                <div className="project-item-btn-container">
                    <a href="#" target="_blank" className="btn btn-primary visitButton disabled" ><BsGithub /> GitHub (after request)</a>
                </div>
            </div>
            <div className="project-item">
                <img src="./assets/projects/angularLogo.png" alt="Angular" className="cornerLogo"/>
                <img src="./assets/projects/stohos_logo_circle.png" alt="Stohos Logo" />
                <h2>Stohos Shooting Range</h2>
                <h4>Description</h4>
                <p>
                    The Stohos Shooting Range webpage offers information on the shooting range and its policies, showcases pictures, and allows users to send messages to the club. Stohos is a shooting range club that provides top-notch facilities and equipment for athletes and shooters to pursue their passion.
                </p>
                <div className="availability">
                    <div>
                        <h6>Available for Trial</h6>
                        <p className="unavailable">
                            No
                        </p>
                    </div>
                    <div>
                        <h6>Available Code Review</h6>
                        <p> Yes</p>
                    </div>
                </div>
                <h4>Technical Summary</h4>
                <p>
                    Stohos Shooting Range is built using Angular 13 with HTML, CSS, TypeScript, and Node.js. While it utilizes a nodemailer, this feature is non-functional in the trial version due to the lack of a Node.js server connection. <b>A demonstration video is available in the video tab that also demonstates the nodemailer's use.</b>
                </p>
                <div className="project-item-btn-container">
                    <a href="https://github.com/denthegreek/stohosShootingClub" target="_blank" className="btn btn-primary visitButton"><BsGithub /> GitHub</a>
                </div>
            </div>
            <div className="project-item">
                <img src="./assets/projects/jsLogo.png" alt="JavaScript Logo" className="cornerLogo"/>
                <img src="./assets/projects/whaleTail.png" alt="Whale Tail" />
                <h2>Whale Tail - Web Chat</h2>
                <h4>Description</h4>
                <p>Whale tale was a REAL TIME web chat / social media platform that I created to communicate with my former classmates and friends. It is still in use and, even though it might be less active. It has the ability to establish friend relationships via friend requests, and send private or public messages or images.</p>
                <div className="availability">
                    <div>
                        <h6>Available for Trial</h6>
                        <p>
                            Yes
                            <br/>(external link)
                        </p>
                    </div>
                    <div>
                        <h6>Available Code Review</h6>
                        <p>Can be provided<br/>(after request)</p>
                    </div>
                </div>
                <h4>Technical Summary</h4>
                <p>
                Whale Tale Web Chat is built with extensive use of PHP and communicates with a phpMyAdmin database to store messages, users, images, and user relationships. 
                Developed without a framework, it uses PHP (including HTML), CSS, and JavaScript. Despite some Bootstrap integration, most components are custom-made. 
                Designed exclusively for desktop, it does not feature responsive design for mobile devices.
                </p>
                <div className="project-item-btn-container">
                    <a href="https://web.njit.edu/~dn242/web_chat/login.php" target="_blank" className="btn btn-primary visitButton"><BsFillPlayCircleFill  /> Visit Trial Version</a>
                    <a href="https://web.njit.edu/~dn242/web_chat/login.php" target="_blank" className="btn btn-primary visitButton disabled"><BsGithub /> GitHub (after request)</a>
                </div>
            </div>
            <div className="project-item">
                <img src="./assets/projects/htmlLogo.png" alt="HTML Logo" className="cornerLogo"/>
                <img src="./assets/projects/dndsGarden.png" alt="Garden Logo" />
                <h2>DNDS Garden</h2>
                <h4>Description</h4>
                <p>DNDS Garden's webpage provides detailed information on their garden services, showcases their expertise, and highlights exclusive offers. It aims to attract potential clients, build trust, and facilitate easy contact. The page emphasizes their comprehensive service range, eco-friendly practices, and luxury features to appeal to discerning customers. </p>
                <div className="availability">
                    <div>
                        <h6>Available for Trial</h6>
                        <p>Yes</p>
                    </div>
                    <div>
                        <h6>Available Code Review</h6>
                        <p>Yes</p>
                    </div>
                    
                </div>
                <h4>Technical Summary</h4>
                <p>
                    This webpage is a customized project developed for a gardening services company, utilizing vanilla HTML, CSS, and JavaScript. It aims to demonstrate how to create an elegant and functional webpage for a small business without relying on frameworks.
                </p>
                <div className="project-item-btn-container">
                    <a href="/dndsGarden/index.html" target="_blank" className="btn btn-primary visitButton"><BsFillPlayCircleFill  /> Visit Trial Version</a>
                    <a href="https://github.com/denthegreek/dndeGarden" target="_blank" className="btn btn-primary visitButton"><BsGithub /> GitHub</a>
                </div>
            </div>
            <div className="project-item">
                <img src="./assets/projects/angularLogo.png" alt="Angular" className="cornerLogo"/>
                <img src="./assets/projects/taskManager.png" alt="Yfansis Logo" />
                <h2>DN Task Manager</h2>
                <h4>Description</h4>
                <p>
                    DN Task Manager is a system designed to store, delete, and manage tasks in a database. Its primary function is to help users efficiently track their weekly tasks.
                </p>
                <div className="availability">
                    <div>
                        <h6>Available for Trial</h6>
                        <p className="unavailable">
                            No
                        </p>
                    </div>
                    <div>
                        <h6>Available Code Review</h6>
                        <p> Yes</p>
                    </div>
                </div>
                <h4>Technical Summary</h4>
                <p>
                    DN Task Manager demonstrates the use of Angular 13 and data storage in a non-relational MongoDB database. Built with HTML, CSS, TypeScript, Node.js, and SQL, it allows users to store and delete tasks and track their completion status. <b>A demonstration video is available on this website in the Video tab.</b>
                </p>
                <div className="project-item-btn-container">

                </div>
                <div className="project-item-btn-container">
                <a href="https://github.com/denthegreek/TaskManager" target="_blank" className="btn btn-primary visitButton"><BsGithub /> GitHub</a>
                </div>
            </div>
            <div className="project-item">
                <img src="./assets/projects/reactLogo.png" alt="React" className="cornerLogo"/>
                <img src="./assets/projects/formerWebsite.png" alt="Former Website" />
                <h2>Former Website</h2>
                <h4>Description</h4>
                <p>
                    My former website was created to showcase information about me and my work experience. It included a home page, business profile, video gallery, and a link to my GitHub page. It has now been replaced by the webpage you are currently browsing.
                </p>
                <div className="availability">
                    <div>
                        <h6>Available for Trial</h6>
                        <p>
                            Yes
                        </p>
                    </div>
                    <div>
                        <h6>Available Code Review</h6>
                        <p> Yes</p>
                    </div>
                </div>
                <h4>Technical Summary</h4>
                <p>
                    My former website was built using React.js, utilizing HTML, CSS, and JavaScript. It was a single-page website and has since been replaced by the webpage you are currently browsing.
                </p>
                <div className="project-item-btn-container">
                    <a href="https://denthegreek.github.io/dimostenis.com_2024_Jul/" target="_blank" className="btn btn-primary visitButton"><BsFillPlayCircleFill  /> Visit Trial Version</a>
                    <a href="https://github.com/denthegreek/dimostenis.com_2024_Jul" target="_blank" className="btn btn-primary visitButton"><BsGithub /> GitHub</a>
                </div>
            </div>
            <div className="project-item">
                <img src="./assets/projects/bootstrapLogo.png" alt="Bootstrap" className="cornerLogo"/>
                <img src="./assets/projects/himalayanAromaLogo.png" alt="Himalayan Aroma" />
                <h2>Himalayan Aroma</h2>
                <h4>Description</h4>
                <p>Himalayan Aroma's webpage introduces visitors to the restaurant’s enchanting Nepali cuisine, showcasing their menu, crew, and commitment to quality. It aims to attract diners with flavorful promises, detailed chef stories, and eco-friendly practices, while encouraging bookings and providing essential contact information for a memorable dining experience.</p>
                <div className="availability">
                    <div>
                        <h6>Available for Trial</h6>
                        <p>
                            Yes
                            <br/>(external link)
                        </p>
                    </div>
                    <div>
                        <h6>Available Code Review</h6>
                        <p className="unavailable">No</p>
                    </div>
                </div>
                <h4>Technical Summary</h4>
                <p>
                    Himalayan Aroma's webpage, built with HTML, CSS, and Bootstrap, demonstrates Bootstrap's capabilities. 
                    It features sections for booking and feedback, though these functionalities are not operational as the page is not connected to a database. 
                    The menu tab has been developed but is not available in the trial version.
                </p>
                <div className="project-item-btn-container">
                    <a href="https://web.njit.edu/~dn242/himalayanaroma.html" target="_blank" className="btn btn-primary visitButton"><BsFillPlayCircleFill  /> Visit Trial Version</a>
                </div>
            </div>
            <div className="project-item">
                <img src="./assets/projects/phpLogo.png" alt="PHP Logo" className="cornerLogo"/>
                <img src="./assets/projects/yfansis.png" alt="Task Manager Logo" />
                <h2>Yfansis Garment LTD</h2>
                <h4>Description</h4>
                <p>Yfansis Garment LTD's webpage provides a seamless user experience with a robust admin side for adding new products and reviewing detailed history. The client side enables easy ordering, availability checks, and product ratings. It features a secure registration and login mechanism, ensuring efficient management and customer satisfaction.</p>
                <div className="availability">
                    <div>
                        <h6>Available for Trial</h6>
                        <p className="unavailable">
                            No
                        </p>
                    </div>
                    <div>
                        <h6>Available Code Review</h6>
                        <p className="unavailable">No<br/>(former class project)</p>
                    </div>
                </div>
                <h4>Technical Summary</h4>
                <p>
                Yfansis Garment LTD's website extensively utilizes PHP and is connected to a phpMyAdmin database. 
                It features both an admin and a client side, managing inventory and enabling purchases with item ratings. The site includes a registration and login mechanism. 
                Built using PHP, HTML, CSS, JavaScript, and Bootstrap, it was developed as a web design class project.
                </p>
                <div className="project-item-btn-container">
                    <button href="" target="_blank" className="btn btn-primary visitButton unavailable-btn" disabled>View its video in the "Videos" tab.</button>
                </div>
            </div>
        </div>
    );
}

export default Projects;